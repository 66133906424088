.payment-manager-body {
  height: 500px;
  .payment-manager-product {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    padding: 20px;
  }
}
