.run-faster {
  .card-content {
    background-color: #fff;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 30px;
    padding: 57px;
    border-radius: 24px;
    .card-title {
      font-size: 50px;
      font-weight: bold;
      color: #f39c12;
    }
    .card-desc {
      font-size: 17px;
    }
    .card-register {
      margin-top: 30px;
      background-color: #f39c12;
      color: #fff;
      font-size: 18px;
      padding: 20px 38px;
      border-radius: 16px;
      border: none;
      transition: all 0.3s;
      &:hover {
        transform: scale(1.2);
      }
      a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    p {
      margin: 0;
    }
  }
  .card-content_text {
    font-weight: 700;
    font-family: sans-serif;
    font-size: 20px;
    span {
      font-size: 17px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 466px) {
    .card-content {
      padding: 57px 30px;
    }
  }
}
