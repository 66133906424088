.course-media-page {
  background-color: #f0f0f0;
  .banner {
    img {
      border-radius: 20px 20px 0px 0;
    }
  }
  .content {
    border-radius: 0px 0 20px 20px;
    padding: 20px;
    span {
      font-size: 14px;
    }
  }
  .course-media-combo {
    .content {
      background-color: #0d3a6d;
      color: #ffffff;
    }
  }
  .course-media-single {
    .content {
      background-color: rgba(255, 255, 255, 1);
      color: black;
    }
  }
}
