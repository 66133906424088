.account-detail-order {
  min-height: 100vh;

  .account-detail-payment {
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    border: 2px solid gray;
    &:hover {
      cursor: pointer;
      border: 2px solid rgba(27, 115, 230, 0.534) !important;
      transition: ease-in-out 100ms;
    }
  }

  .payment-selected {
    border: 2px solid rgba(27, 115, 230, 0.534) !important;
  }
  .account-detail-payment-img {
    width: 100%;
  }
  .account-detail-bank {
    width: 100%;
    height: 80px;
    border: 1px solid gray;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
      border: 2px solid rgba(27, 115, 230, 0.534) !important;
      transition: ease-in-out 100ms;
    }
  }
  .bank-selected {
    border: 2px solid rgba(27, 115, 230, 0.534) !important;
    transition: ease-in-out 100ms;
  }
  .hover-disable {
    &:hover {
      cursor: unset !important;
      border: 2px solid gray !important;
      transition: ease-in-out 100ms;
    }
  }
  .render-bank {
    /* This timing applies on the way OUT */
    // transition: ease-in-out 0.8s;
    transition: height 2s;
  }
  .search-bank {
  }
  .account-detail-information {
  }
  .account-total-information {
    display: flex;
    justify-content: end;
    font-size: larger;
    font-weight: bold;
    color: red;
  }
  .detail-product {
    position: relative;
    background: rgb(167, 208, 209);
    border: 1px solid rgba(27, 115, 230, 0.534);
    margin-bottom: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    //   height: 2px;
    //   background: black;
    // }
    // &:last-child {
    //   &::after {
    //     content: unset !important;
    //   }
    // }
  }
  .product-count {
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    .break-line {
      height: 100%;
      width: 1px;
      background-color: gray;
    }
  }
}
