.tfs-fixed-bottom {
  z-index: 888;
  position: fixed;
  bottom: 0;
  height: 80px;
  background-image: linear-gradient(rgb(255, 0, 191), #8420be);
  width: 100%;

  .img-register {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 50px;
      bottom: 0;
    }

    .tfs-btn-register {
      // position: absolute;
      font-size: 24px;
    }
  }

  .title-h2 {
    font-size: 28px !important;
  }

  .tfs-fixed-bottom-content {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }
}

.tfs-btn-register {
  color: black;
  font-size: 24px;
  font-weight: 600px;
  padding: 10px;
  background: linear-gradient(to right, #cfaf75, #fed993b1, #cfaf75);
}

.animate-ring {
  -webkit-animation: phonering-alo-circle-img-anim 2s infinite ease-in-out;
  animation: phonering-alo-circle-img-anim 2s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}

@media (max-width: 768px) {
  .tfs-fixed-bottom {
    height: 50px !important;
    text-align: center;

    .img-register {
      right: 20px;
      bottom: 0;

      .tfs-btn-register {
        font-size: 18px !important;
      }
    }

    h2 {
      font-size: 18px;
    }

    .title-h2 {
      font-size: 18px !important;
    }

    .tfs-fixed-bottom-content {
      display: block !important;

      .title-h2 {
        font-size: 18px !important;
      }

      p {
        margin-bottom: 15px !important;
      }
    }
  }
}
