.submit-question-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-question-btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.submit-question-btn:active {
    background-color: #004080;
    transform: scale(0.95);
}

.submit-question-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.pagination-controls button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.pagination-controls button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.pagination-controls button:active {
    background-color: #004080;
    transform: scale(0.95);
}

.pagination-controls button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.pagination-controls button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}