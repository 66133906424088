// :root {
//   --gray-0: rgb(200, 200, 200);
//   --shadow: rgb(61, 71, 82);
// }

// Font Style
$font-primary: "Open Sans", sans-serif;
$font-secondary: "Raleway", sans-serif;
// Color
$lifeuni-color: #67c8ec;
$lifeuni-color2: #0f253a;
$lifeuni-color3: #3782c4;
$black-0: #000;
$black-text: #333;
$text-hover: rgb(22 248 182);
$white-0: #fff;
$gray-0: #f8f9ff;
$gray-1: #f3f3f3;
$gray-2: #aab9bb;
$gray-3: #617083;
$gray-4: #ecedf6;
$c-white-0: #fff; //default
$c-black-0: #333; // default
$c-gray-0: #9b9b9b;
$c-gray-1: rgba(246, 246, 246, 0.5);
$c-gray-2: #4a4a4a;
$c-gray-3: #949494;
$c-gray-4: #e4e4e4;
$c-gray-5: #222;
$c-gray-6: #bbb;
$blue-0: #d1edf3;
$blue-1: #3777bc;
$blue-2: #26435b;
$green-0: #00d664;
$red-0: #f75e5e;
$yellow-0: #feda6a;
$c-blue-1: #1f2e46;
$c-orange-0: #fb4226;
$c-blue-0: #103e79;
$c-green-1: #84c441;
$c-lightPink-1: #ffd5cd;
$c-lightPink-2: #efbbcf;
$c-beyge-1: #cbbd9a;
$c-beyge-2: #cbbd9a54;
$text-green: rgb(22 248 182 / var(--tw-text-opacity));
$bg-primary: #f8f9ff;

// font weight
// variable font-size
$fs-0: 14px; //default
$fs-1: 13px;
$fs-2: 18px;
$fs-3: 20px;
//Còn 1 font-size: 36px;
$fs-4: 32px;

//variable margin
$mg-0: 2px;
$mg-1: 10px;
$mg-2: 20px;

//variable border-radius
$border-radius: 4px;

//variable padding
$pd-0: 5px; //default
$pd-1: 10px;
$pd-2: 15px;
$pd-3: 20px;

//variable line-height
$line-height-0: 1.43; //default

//variable font-weight
$fw-normal: normal;
$fw-bold-0: 600;
$fw-bold-1: 700;
$fw-bold: bold;
