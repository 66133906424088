.profileMainInfo {
  border-bottom: 1px solid #dbeaf5;
}

.profileMedia {
  padding-bottom: 30px;

  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
}

.labelAvatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px dashed rgb(229, 229, 229);
  img {
    max-width: 55%;
  }
  input {
    display: none;
  }
}

.profileOther {
  h5 {
    padding-top: 30px;
    color: #273167;
  }
}
.wrapperUpload {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  position: relative;

  &:hover {
    .buttonDeleteImage {
      display: flex;
      opacity: 1;
    }
  }
}

.avatarHasUpload {
  min-width: 100%;
  height: 100%;
}

.buttonDeleteImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;

  width: 60px;
  height: 60px;

  background: #fff;
  color: #f45757;
  font-size: 16px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
}

.date-picker {
  margin-top: 72px;
}

@media screen and (max-width: 1199px) {
  .labelAvatar {
    width: 125px;
    height: 125px;
  }
}
@media screen and (max-width: 992px) {
  .date-picker {
    margin-top: 20px;
  }
}
