.accordion-content {
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}
.accordion {
    background: #fff;
    color: #000;
    transition: all .3s;
    cursor: pointer;

    &.active {
        background: #cbbd8c;
        color: #fff;
    }
}

.accordion-title {
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 10px;
    border-left: 5px solid #cbbd8c;
    &_primary {
        font-size: 20px;
    }
}

.accordion-content {
    background: #fff;
    color: #000;
    text-align: left;
    font-size: 18px;

    &_primary {
        padding: 25px 25px 15px;
    }

}