.problemBusiness {
  .content01 {
    background: #cbbd9a;
    color: white;
    padding: 40px 0;

    h2 {
      color: black;
      margin: 0;
      font-size: 25px;
    }
  }
  .content02 {
    background: #25252d;
    color: #cbbd9a;
    text-align: justify;
  }

  .wrapper-content {
    .class-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn-default {
      padding: 8px 20px;
      text-transform: uppercase !important;
      font-size: 14px !important;
      border: none;
      outline: none;
      font-weight: 600;
      transition: all 0.3s;

      &:hover {
        background: #333;
        color: #fff;
      }
    }
  }
}

.wrapper-detail {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: justify;
  margin-top: 20px;

  img {
    width: 50px;
    height: 50px;
  }

  .description {
    h3 {
      margin-top: 10px;
      color: #fff;
    }

    p {
      color: #fff;
    }
  }
}

.text-heading {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 0;
  text-shadow: none;
}

.content02 {
  overflow: hidden;
  transition: all 0.6s ease;

  .padding-body {
    padding-top: 25px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper-content {
    text-align: center;
    .btn-default {
      margin-top: 20px;
    }
  }

  .description {
    h3 {
      height: unset;
      text-align: center;
    }
  }
}
