$border-radius: 15px;
$color-heading: #47526b;
$color-desc: #b2bdd1;
$color-button: #8472f1;

.wrapper_cardItem {
  .cardItem {
    border: 1px solid #e4e6eb;
    border-radius: $border-radius;
    box-shadow: rgba(50, 50, 93, 0.2) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.2) 0px 8px 16px -8px;

    &-img {
      position: relative;

      img {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      .overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          transparent,
          transparent,
          transparent,
          rgb(50, 55, 84) 99%
        );
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }

    &-text {
      padding: 40px 30px;

      &_heading {
        margin-bottom: 30px;
        color: $color-heading;
        font-weight: 600;
        text-transform: uppercase;
      }

      &_desc {
        color: $color-desc;
        margin-bottom: 30px;
      }

      &_link {
        color: $color-desc;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5px;
        display: inline-block;
        transition: all 0.3s;

        &:hover {
          color: $color-button;

          &::after {
            width: 100%;
          }
        }

        &::after {
          content: "";
          height: 2px;
          width: 0%;
          display: flex;
          background: $color-button;
          transition: all 0.3s;
        }
      }
    }
  }
}
