// Colors
$white: #fff;
$black: #000;
$primary: #d4a024;
$gray: #616161;

.wrapper-timeline {
  width: 100%;

  .swiper-pagination {
    right: 16% !important;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    z-index: 9;

    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: rgba($white, 0.3);
    }

    &-bullet {
      text-align: center;
      opacity: 1;
      background: transparent;
      color: $primary !important;
      margin: 15px 0 !important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -32.5px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $primary;
        transform: scale(0);
        transition: 0.2s;
      }
    }
  }

  .swiper-pagination-bullet-active {
    color: $primary !important;

    &::before {
      transform: scale(1) !important;
    }
  }
}

@media screen and (min-width: 768px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 1350px) {
}
// Timeline
.timeline {
  .swiper-container {
    height: 600px;
    width: 100%;
  }

  .swiper-wrapper {
    transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
  }

  .swiper-slide {
    // position: relative;
    color: $white;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: -115%;
      bottom: -10%;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.7);
      box-shadow: -230px 0 150px 60vw rgba($black, 0.7);
      border-radius: 100%;
    }

    &-content {
      position: absolute;
      text-align: center;
      width: 80%;
      max-width: 410px;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;
    }

    .timeline-year {
      display: block;
      font-family: "Gilroy";
      font-style: italic;
      font-size: 42px;
      margin-bottom: 50px;
      transform: translate3d(20px, 0, 0);
      color: $primary;
      font-weight: 300;
      opacity: 0;
      transition: 0.2s ease 0.4s;
    }

    .timeline-title {
      font-weight: 800;
      font-size: 34px;
      margin: 0 0 30px;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: 0.2s ease 0.5s;
    }

    .timeline-text {
      line-height: 1.5;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: 0.2s ease 0.6s;
      font-size: 18px;
      text-align: left;
    }

    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: 0.4s ease 0.6s;
      }

      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: 0.4s ease 1s;
      }

      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: 0.4s ease 1.2s;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 20px 20px;
    top: 15%;
    width: 20px;
    height: 20px;
    margin-top: 0;
    z-index: 2;
    transition: 0.2s;

    &::after {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover {
      transform: translateX(-3px);
    }
  }

  .swiper-button-next {
    right: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover {
      transform: translateX(3px);
    }
  }

  @media screen and (min-width: 768px) {
    .swiper-slide {
      &::after {
        right: -30%;
        bottom: -8%;
        width: 240px;
        height: 50%;
        box-shadow: -230px 0 150px 50vw rgba($black, 0.7);
      }

      &-content {
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 410px;
        font-size: 11px;
        text-align: right;
      }

      .timeline-year {
        margin-bottom: 0;
        font-size: 32px;
      }

      .timeline-title {
        font-size: 46px;
        margin: 0;
      }
    }

    .swiper-button-prev {
      top: 15%;
      left: auto;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);

      &:hover {
        transform: rotate(90deg) translate(-3px, 10px);
      }
    }

    .swiper-button-next {
      top: auto;
      bottom: 15%;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);

      &:hover {
        transform: rotate(90deg) translate(3px, 10px);
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .swiper-slide {
      &::after {
        right: -20%;
        bottom: -12%;
        width: 240px;
        height: 50%;
        box-shadow: -230px 0 150px 39vw rgba($black, 0.7);
      }

      &-content {
        right: 25%;
      }
    }
  }
}
