.video-streaming {
  background-color: white;
  .MuiPaper-root {
    background-color: white;
  }
  .isLearning {
    background-color: rgb(39, 37, 37) !important;
    color: white;
  }
  .isLearned {
    background-color: rgb(0, 171, 85);
    color: white;
  }
  .answer-question {
  }
  .quiz {
    min-height: 100vh;
    .btn {
      &:hover,
      &:visited,
      &:focus {
        border: 1px solid white;
      }
    }
    .thanks-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 250px;
    }
  }

  // .MuiSvgIcon-root {
  //   color: white;
  // }
  // background-color: #1d212c;
  // .wrapper-menu-admin {
  //   .menu-selected {
  //     color: rgb(0, 171, 85) !important;
  //     font-weight: bold;
  //     svg {
  //       color: rgb(0, 171, 85);
  //     }
  //     li {
  //       background: rgba(0, 171, 85, 0.08) !important;
  //     }
  //   }
  //   a {
  //     color: #333;
  //     &:hover {
  //       color: #999;
  //       svg {
  //         color: #5361fd;
  //       }
  //       & > li {
  //         background: #303440 !important;
  //         margin-left: 13px;
  //         border-radius: 24px 0px 0px 24px;
  //         border-right: 18px orange solid;
  //       }
  //     }
  //   }
  // }
  // .wrapper-single-form {
  //   padding: 116px 16px;
  // }
  // .table-toolbar {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   &__icon {
  //     font-size: 20px;
  //     cursor: pointer;
  //   }
  // }
  // .course-online-search-bar {
  //   border-radius: 20px;
  //   background: #282c38;
  //   margin: 8px;
  // }
}
.container-login {
  margin: 50px auto;
  padding: 20px;
  background-color: #fff; /* White container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  h1 {
    color: #007bff; /* Blue heading color */
  }

  p {
    color: #28a745; /* Green paragraph color */
  }

  .button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff; /* Blue button background */
    color: #fff; /* Button text color */
    text-decoration: none;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
}
