$color-primary: #FFFF2E;
$color-secondary: #FF0000;
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Montserrat:wght@700&family=Poppins:wght@700&display=swap');



.color-box-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
}

@keyframes jump {

    0%,
    100% {
        transform: translateY(0);
        /* Start and end at the original position */
    }

    50% {
        transform: translateY(-10px);
        /* Move up 10px in the middle of the animation */
    }
}

.price-fsp {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 22px;
    font-family: "Montserrat", "Arial", sans-serif;
    text-transform: uppercase;
    font-weight: bolder;
    animation: jump 0.5s ease-in-out infinite;
    /* Applying the jump animation */
}

.price-promo-fsp {
    background: -webkit-linear-gradient(white, white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 22px;
    font-family: "Montserrat", "Arial", sans-serif;
    text-transform: uppercase;
    font-weight: bolder;
    position: relative;
}

.price-promo-fsp::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0%;
    width: 80%;
    height: 3px;
    background-color: white;
    transform: translateY(-50%);
}

.color-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    flex: 1 1 150px; // Make boxes flexible with a minimum width
}

.color-box-item {
    width: 70px;
    height: 30px;
    border-radius: 8px;
    margin-right: 15px;
    border: 1px solid #ccc;
}

.description {
    font-size: 0.8rem;
    color: #fff;
    font-weight: bold;
}

.container-pyramid-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.shaking-card {
    border: 2px solid #ddd;
    width: 200px;
    padding: 25px;
    text-align: center;
    font-size: 16px;
    background-color: white;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    color: #fff;
    background: linear-gradient(135deg, #1f476c, #101010);
}

.shaking-card:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    border-color: $color-secondary;
}

.shaking-card-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(148, 147, 147, 0.89);
    padding: 20px;
    text-align: center;
    height: max-content;
    font-size: 18px;
    background-color: white;
    color: #fff;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 5px;
    animation: shake 1.5s infinite ease-in-out;
    background: linear-gradient(135deg, #1f476c, #101010);
}

.shaking-card-detail:hover {
    transform: scale(1.1);
    animation: none;
    border-color: $color-secondary;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.best-seller-border {
    border: 2px solid $color-primary;
    box-shadow: 0px 0px 10px $color-primary;
    animation: shake 1s infinite ease-in-out;
}

.card-detail {
    border: 2px solid #ccc;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.promo-card {
    border-radius: 35px;
    width: 80%;
    margin-top: 20px;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: start;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
    }

    .promo-description {
        font-size: 2rem;
        margin-top: 15px;
        margin-bottom: 15px;
        line-height: 2;
        color: rgb(255, 133, 96);
        font-weight: bolder;
    }
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.mountain-title {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: relative; // Add this to create a context for absolute positioning
}

.mountain-title .coach-vas {
    width: 200px;
    height: 250px;
    position: relative; // Position relative to make it layered
    z-index: 1; // Ensure it's below the 'fsp' image
    margin-bottom: -50px; // Adjust the overlap, reduce the space between images
}

.mountain-title .fsp {
    width: 30%;
    height: auto;
    position: relative;
    z-index: 2;
    animation: scaleAnimation 3s ease-in-out infinite;
}

.mountain-title-text {
    font-size: 22px;
    font-weight: bold;
    color: white;
}

.promo-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: white;
}

.listFSP ul li {
    font-size: 1.3rem;
    font-weight: bolder;
    margin-bottom: 20px;
}

.card-detail h3 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #333;
}

.card-detail p {
    font-size: 16px;
    color: #666;
}

ul.custom-list {
    font-size: 14px;
    text-align: left;
    margin-left: 0;
    padding-left: 20px;
}

.best-seller-badge {
    position: absolute;
    bottom: -65px;
    right: -65px;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    color: white;
}

.best-seller-badge img {
    width: 150px;
    height: 150px;
}

.screen-split-container {
    height: auto;
}

.screen-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.centered-text {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.video-study-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
}

.video-study-container iframe {
    width: 800px;
    height: 600px;
    border: 0;
}

.video-study-content {
    color: white;
}

.tfs-fixed-bottom {
    z-index: 888;
    position: fixed;
    bottom: 0;
    height: 80px;
    background-image: linear-gradient(rgb(255, 0, 191), #8420be);
    width: 100%;

    .img-register {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 50px;
            bottom: 0;
        }

        .tfs-btn-register {
            font-size: 24px;
        }
    }

    .title-h2 {
        font-size: 28px !important;
    }

    .tfs-fixed-bottom-content {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            margin-bottom: 0;
        }
    }
}

.tfs-btn-register {
    color: black;
    font-size: 24px;
    font-weight: 600;
    padding: 10px;
    background: linear-gradient(to right, #cfaf75, #fed993b1, #cfaf75);
}

.blurred-content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #333;
    text-align: center;
}

.login-button {
    position: relative;
    z-index: 20;
    /* Keep the button on top of the blur */
    color: white;
    padding: 15px 30px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #0056b3;
}

.question-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.question-modal-content {
    background-color: #808080;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    text-align: center;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
}

.animate-ring {
    animation: phonering-alo-circle-img-anim 2s infinite ease-in-out;
    transform-origin: 50% 50%;
    display: flex;
}

@media (max-width: 1200px) {
    // .color-box-wrapper {
    //     margin-bottom: 10px;
    //     flex: 1 1 100%; // Make boxes take full width on smaller screens
    // }

    .promo-description {
        font-size: 1.5rem; // Adjust text size for smaller screens
    }

    .video-study-container iframe {
        width: 600px;
        height: 450px;
    }

}

@media (max-width: 768px) {
    .video-study-container iframe {
        width: 400px;
        height: 300px;
    }

    .mountain-title-text {
        font-size: 18px; // Smaller font size for mobile
    }

    .promo-title {
        font-size: 1.25rem; // Adjust for smaller screens
    }

    .best-seller-badge {
        bottom: -50px; // Adjust position for smaller screens
    }

    .card-detail {
        padding: 15px; // Adjust padding for mobile
    }

    .color-box-item {
        width: 80px; // Adjust item width for mobile
    }
}

@media (max-width: 480px) {
    .video-study-container iframe {
        width: 300px;
        height: 200px;
    }

    .video-study-container {
        width: 260px;
        height: 180px; // Make video container responsive
    }

    .color-box-item {
        width: 60px; // Even smaller item size for very small screens
    }

    .promo-description {
        font-size: 1.2rem; // Reduce further for small devices
    }

    .promo-card {
        padding: 10px; // Reduce padding for very small screens
    }

    .tfs-fixed-bottom {
        height: 60px; // Adjust height for mobile
    }
}