.self-mastery{
    .introduce-content{
        // color: #949392;
        color:black;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
    }
}
