.register-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 1200px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
}


.image-section {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.image {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin: 0;
    padding: 0;
    display: block;
}

.form-section {
    padding: 40px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
}

h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Default for larger screens */
    gap: 15px;
}

.form-input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
}

.form-input:focus {
    border-color: #007bff;
}

.submit-button {
    width: 40%;
    justify-self: center;
    grid-column: span 2;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: black;
    background: linear-gradient(to right, #FFE2A3, #FFAD1D);
    /* Linear gradient from FFE2A3 to FFAD1D */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .register-container {
        flex-direction: column;
    }

    .image-section {
        width: 100%;
    }

    .image {
        max-width: 100%;
        height: auto;
    }

    .form-section {
        width: 100%;
        padding: 20px;
    }

    .form-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .submit-button {
        grid-column: span 1;
    }
}