.graduate {
  color: black;
  background-color: #3782c4;

  ul {
    margin-left: 15px;
    li {
      margin-bottom: 10px;
    }
  }

  iframe {
    width: 100%;
  }
  .background {
    background: url("https://lpe.vn/img/lpe/chuong-trinh-huan-luyen/offline/graduate/10-2.png");
    background-size: cover;
    color: white;
  }
  .background2 {
    background: url("https://lpe.vn/img/lpe/chuong-trinh-huan-luyen/offline/graduate/10-3.png");
    background-size: cover;
    color: white;
    text-align: left !important;
    padding: 10px 0;
    h3 {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
}
