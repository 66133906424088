.breadcrumb {
  display: flex;
  align-items: center;
  background-color: #fff !important;
  justify-content: flex-end;

  li {
    list-style: none;
    margin: 0 5px;

    a {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #adb5bd;
      border-radius: 3px;
      color: #25252d;
      font-size: 13px;
      transition: all 0.3s;

      &:hover {
        background-color: #e4e6ec;
      }

      i {
        font-size: 18px;
        color: #adb5bd;
      }

      &.active {
        border: 1px solid #0270ad;
        color: #0270ad;

        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
