// default
// .body-dark{
//   --tw-bg-opacity: 1;
// background-color: rgb(13 18 36/var(--tw-bg-opacity));
// --tw-text-opacity: 1;
// color: rgb(211 216 232/var(--tw-text-opacity));
// }
body {
  background-color: #f6f4f1 !important;
}
$color-text: #0f0a64;
$color-text2: #0463c3;
$color-text-primary: #454545;
$color-text-secondary: #5d5d5d;
$color-text-teriaty: #a4a4a4;
$bg-color: #f6f4f1;
$blue-500: #1081e7;
$blue-600: #0463c3;
$color-secondary: #9eacc7;

$blue2: #3a9df6;
iframe {
  width: 100%;
}
$fontSizeMedium: 14px;
p,
span,
div {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", "Arial", sans-serif;
}

.Toastify__toast-container--top-right {
  top: 5em;
}

@mixin headline {
  font-family: "Montserrat", "Arial", sans-serif;
  text-transform: uppercase;
  // white-space: nowrap;
  --color: #fff;
  --color-accent-1: hsl(50, 100%, 79%);
  --color-accent-2: hsl(313, 100%, 39%);
  // filter: drop-shadow(0.15em 0.1em 0.3em var(--color-accent-2));
  color: var(--color);
  font-weight: 800;
  // text-shadow: 4px 0 10px rgba(0, 0, 0, 0.13);
  background: -webkit-linear-gradient(white, #8420be);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.headline-h1 {
  @include headline();
  font-size: 48px;
  line-height: 48px;
}
.headline-h2 {
  @include headline();
  font-size: 32px;
  line-height: 32px;
}
.headline-h3 {
  @include headline();
  font-size: 28px;
  line-height: 28px;
}
.headline-h4 {
  @include headline();
  font-size: 22px;
  line-height: 22px;
}
@keyframes movingRegister {
  0% {
    top: 0%;
  }
  50% {
    top: calc(0% + 10px);
  }
  100% {
    top: 0%;
  }
}
@mixin button {
  font-family: "Gilroy", sans-serif;
  line-height: 17px;
  color: white;
  padding: 12px 28px;
  border-radius: 40px;
  font-weight: bold;
}
.button-primary {
  @include button;
  background-color: #0463c3;
}
.button-secondary {
  @include button;
  background-color: rgba(4, 99, 195, 0.1);
  color: #0463c3;
  &:hover {
    color: white;
  }
}
.button-critical {
  @include button;
  background-color: #c8513a 10%;
  color: #c8513a;
}
.button-orange {
  @include button;
  color: black;
  background-color: #ffc771;
}
.bg-banner {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
}
.section-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: rgb(0, 0, 0);
  opacity: 0.3;
  mix-blend-mode: multiply;
  will-change: transform, opacity;
}
.banner-content {
  position: absolute;
  bottom: 100px;
  font-family: "Gilroy-Bold", sans-serif;
  &-text {
    color: $blue2;
    font-size: $fontSizeMedium;
    text-transform: uppercase;
  }
  &-header {
    color: white;
    font-size: 40px;
  }
}
.pointer-cursor {
  cursor: pointer;
}
.heading {
  font-family: "Gilroy-Bold", sans-serif;
  &-desc {
    color: $color-secondary;
    font-size: 16px;
    font-family: "Gilroy", sans-serif;
  }
  &-text {
    font-weight: 500;
    padding-top: 30px;
    color: $color-text;
    font-family: "Gilroy-Light", sans-serif;
    margin-bottom: 0;
  }
}
.default-price {
  color: #808080;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: line-through;
  span {
    text-decoration-line: line-through;
    --tw-text-opacity: 1;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: 0.1rem;
  }
  // &:hover{
  //     color:rgb(22 248 182);
  // }
}
.promo-price {
  color: #3a9df6;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.body-white {
  background-color: #fff;
}
.br-top-left {
  border-top-left-radius: 20px;
}
.br-top-right {
  border-top-right-radius: 20px;
}
.br-bottom-left {
  border-bottom-left-radius: 20px;
}
.br-bottom-right {
  border-bottom-right-radius: 20px;
}

.title-h1 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Gilroy";
}
.title-h2 {
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
  font-family: "Gilroy";
}
.title-h3 {
  line-height: 24px;
  font-size: 24px;
  font-weight: 600;
  font-family: "Gilroy";
}
.title-h4 {
  line-height: 20px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Gilroy";
}
.bold {
  font-weight: bold;
}
.text-blue {
  color: $color-text;
}
.text-blue2 {
  color: $color-text2;
}
.description-gray {
  color: #5d5d5d;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  display: flex !important ;
  justify-content: flex-start !important;
  padding: 6px 16px !important;
}
.description {
  font-size: 16px;
  font-weight: bold !important;
  margin-top: 0 !important;
  margin-bottom: 0;
}
.flex-alignitem {
  display: flex;
  align-items: center;
}
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 80px;
  z-index: 999;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.order2 {
  order: 2;
}
.flex-justcenter {
  display: flex;
  justify-content: center;
}
.box-center {
  border: 1px solid #ededed;
  justify-content: center;
  align-items: center;
  display: inline-block;
  background-color: white;
}
.input-field {
  background-color: white;
}
.radio-button {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.space-around {
  display: flex;
  justify-content: space-around;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.text-justify {
  text-align: justify;
}
.text-green {
  --tw-text-opacity: 1;
  color: rgb(22 248 182 / var(--tw-text-opacity));
}
.text-lg {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-md {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-center {
  text-align: center;
}

.font-size12 {
  font-size: 12px !important;
}
.font-size14 {
  font-size: 14px !important;
}
.font-size16 {
  font-size: 16px !important;
}
.font-size18 {
  font-size: 18px !important;
}
.font-size20 {
  font-size: 20px !important;
}
.font-size22 {
  font-size: 22px !important;
}
.font-size24 {
  font-size: 24px !important;
}
.text-white {
  color: white;
}
.centerXYPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-source {
  position: relative;
  height: 500px;
}

.quote {
  margin: 40px auto;
  display: block;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #cfc547;
  text-align: center;
  letter-spacing: 1px;
  text-shadow: 10px 10px 25px rgb(81, 67, 21), -10px 10px 25px rgb(81, 67, 21),
    -10px -10px 25px rgb(81, 67, 21), 10px -10px 25px rgb(81, 67, 21);
  font-size: 30px;
}
.input-select {
  width: 100%;
}
.progress-bar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.box-radius {
  background-color: #282c38;
  border-radius: 26px;
  color: white;
}
.video-player {
  iframe {
    border-radius: 26px 26px 0 26px;
  }
}

@mixin button-buy-course {
  --tw-text-opacity: 1;
  color: rgb(255 255 255);
  text-decoration-color: #ff4bbe;
  font-weight: 600;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 0.2s;
  transition-property: all;
  text-decoration-line: none;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 9999px;
}
.action-buy-course {
  @include button-buy-course;
  background-image: linear-gradient(to right, #f926ae, #8028ee);
  &:hover {
    color: rgb(22 248 182);
  }
}
.action-buy-course-blue {
  @include button-buy-course;
  background-image: linear-gradient(to right, #8028ee, #8028ee);
  &:hover {
    color: rgb(22 248 182);
  }
}
.inline-block {
  display: inline-block;
}
.w-6 {
  width: 1.5rem;
}
.item-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.flex {
  display: flex;
}
hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
  border-top-color: white;
}
.register {
  font-size: 30px;
  background: #f7941e;
  border-radius: 50px;
  padding: 10px 20px 10px 20px;
  color: white;
  box-shadow: black 5px 5px 35px 0px;
  margin: auto;
}
.courseTitle {
  background-color: white;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  color: black;
  text-shadow: 0 0 20px orange;
  border: transparent;
  font-style: italic;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.visible-sm-xs {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

header {
  padding: 0 !important;
}

// ::-webkit-scrollbar {
//   width: 6px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #fff;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #000;
// }

// style arrow color
polygon {
  fill: #fff;
}

// MenuBar
ul li:hover > a {
  background-color: #000 !important;
  color: #fff !important;
  transition: all 0.6s;
}

@media (max-width: 991.98px) {
  .video-source {
    height: 500px !important;
  }
}

@media screen and (max-width: 768px) {
  .video-source {
    height: 350px !important;
  }
  .title {
    font-size: 30px;
  }

  .description {
    font-size: 14px !important;
  }
  .order2 {
    order: unset !important;
  }
  .order-last {
    order: unset;
  }
  .hidden-sm-xs {
    display: none !important;
  }
  .visible-sm-xs {
    display: block !important;
  }
}
@media screen and (max-width: 992px) {
  .MuiDrawer-root {
    .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
      width: 50% !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .MuiDrawer-root {
    .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
      width: 70% !important;
    }
  }
}
@media screen and (max-width: 400px) {
  .MuiDrawer-root {
    .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
      width: 100% !important;
    }
  }
}

.MuiAccordionDetails-root {
  padding: 0 !important;
  display: block !important;
  border-bottom: none !important;
}

// Carousel code
.slider {
  min-height: 100vh;
}

[not-existing] {
  zoom: 1;
}

lesshat-selector {
  -lh-property: 0;
}

.loader {
  border: 3px solid $gray-0;
  border-top: 3px solid #3777bc; /* Blue */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
  margin-right: 5px;

  &.image {
    width: 60px;
    height: 60px;
    border: 5px solid $gray-0;
    border-top: 5px solid #3777bc;
    border-bottom: 5px solid #3777bc;
    border-radius: 50%;
    animation: spin 0.5s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper-toast {
  display: flex;
  align-items: center;
  color: $black-text;
}

.Toastify__toast--success {
  background: $gray-1 !important;
  border-left: 4px solid $green-0;

  i {
    margin-right: 10px;
    color: $green-0;
    font-size: 20px;
  }
}

.Toastify__toast--error {
  background: #f3f3f3 !important;
  border-left: 4px solid $red-0;

  i {
    margin-right: 10px;
    color: $red-0;
    font-size: 20px;
  }
}

.Toastify__toast--warning {
  background: #f3f3f3 !important;
  border-left: 4px solid $yellow-0;
  i {
    margin-right: 10px;
    color: $yellow-0;
    font-size: 20px;
  }
}

.profileBox {
  padding: 30px;
  border-radius: 15px;
  background: #fff;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.wrapper-accordion {
  overflow: hidden;
  transition: all 0.3s;
}

.accordion-title {
  // background-color: #dfe8f6;
  color: #3777bc;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  padding: 10px 20px;
}

@media screen and (max-width: 1199px) {
  .profileBox {
    padding: 30px 10px;
  }
}

.hotline-phone-ring-wrap {
  position: fixed;
  bottom: 30px;
  left: 0;
  z-index: 999;
}
.logo-zalo-wrap {
  position: fixed;
  bottom: 90px;
  left: 0;
  z-index: 999;
}
.logo-messenger-wrap {
  position: fixed;
  bottom: 90px;
  right: 0;
  z-index: 999;
}
.hotline-phone-ring {
  position: relative;
  visibility: visible;
  background-color: transparent;
  width: 110px;
  height: 110px;
  cursor: pointer;
  z-index: 11;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transition: visibility 0.5s;
  left: 0;
  bottom: 0;
  display: block;
}
.hotline-phone-ring-circle {
  width: 85px;
  height: 85px;
  top: 10px;
  left: 10px;
  position: absolute;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid #3777bc;
  -webkit-animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0.5;
}
.hotline-phone-ring-circle-fill {
  width: 55px;
  height: 55px;
  top: 25px;
  left: 25px;
  position: absolute;
  background-color: #3777bc;
  border-radius: 100%;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.hotline-phone-ring-img-circle {
  background-color: #3777bc;
  width: 33px;
  height: 33px;
  top: 37px;
  left: 37px;
  position: absolute;
  background-size: 20px;
  border-radius: 100%;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hotline-phone-ring-img-circle .pps-btn-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.hotline-phone-ring-img-circle .pps-btn-img svg {
  width: 20px;
  height: 20px;
  color: #fff;
}
.hotline-bar {
  position: absolute;
  background: #3777bc;
  height: 40px;
  width: 200px;
  line-height: 40px;
  border-radius: 3px;
  padding: 0 10px;
  background-size: 100%;
  cursor: pointer;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
  z-index: 9;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50px !important;
  /* width: 175px !important; */
  left: 33px;
  bottom: 37px;
}
.hotline-bar > a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  text-indent: 50px;
  display: block;
  letter-spacing: 1px;
  line-height: 40px;
  font-family: Arial;
}
.hotline-bar > a:hover,
.hotline-bar > a:active {
  color: #fff;
}
@-webkit-keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.5) skew(1deg);
    -webkit-opacity: 0.1;
  }
  30% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    -webkit-opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    -webkit-opacity: 0.1;
  }
}
@-webkit-keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.6;
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.6;
  }
}
@-webkit-keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
}

// css register
.popup {
  position: relative;
  border-radius: 40px;
  .popup-background {
    border-radius: 40px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-origin: content-box;
    background-position: 50% 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: url("https://w.ladicdn.com/s962x1857/648697fd80c97200121bfb81/167182069_2926028217674018_5985991768046285403_n-20230912103350-ycnao.jpg");
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-color: rgb(0, 0, 0);
    opacity: 0.69;
    mix-blend-mode: multiply;
    will-change: transform, opacity;
    border-radius: 40px;
  }
  .general {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-top: 50px !important;
  }
  .platinum {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 50px !important;
  }
  .vip {
    background-image: linear-gradient(rgb(13, 98, 242), rgb(5, 31, 77));
    border-radius: 20px;
  }
  .price-box {
    box-shadow: rgb(0, 0, 0) -15px 15px 20px -15px;
    padding: 25px;
    background-color: rgb(5, 31, 77);
    .icon {
      width: 30px;
      height: 30px;
    }
    .headline {
      color: white;
      margin-bottom: 0;
      width: unset !important;
      font-family: "Yeseva One", cursive;
      font-size: 25px;
      line-height: 1.6;
      color: rgb(255, 255, 255);
      letter-spacing: 1px;
    }
    .box-promotion {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      margin-left: -37px;
      background-color: rgb(255, 255, 255);
      h3,
      h4 {
        margin-bottom: 0;
      }
      .default-price {
        font-family: Montserrat, sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: rgb(5, 31, 77);
        text-decoration-line: line-through;
        text-align: center;
      }
      .promo-price {
        font-family: Montserrat, sans-serif;
        font-size: 26px;
        font-weight: bold;
        color: rgb(242, 67, 13);
        text-align: center;
      }
      .promo-text {
        text-transform: uppercase;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: rgb(242, 67, 13);
        text-align: center;
        margin-bottom: 0;
      }
    }
    .list-paragraph {
      font-family: Roboto, sans-serif;
      font-size: 18px;
      line-height: 1.4;
      color: rgb(255, 255, 255);
      ul {
        list-style: none;
        padding-left: 0px;
        li {
          padding-bottom: 10px;
          padding-left: 25px;
          color: #fff;
          &:before {
            width: 14px;
            height: 14px;
            top: 5px;
            content: "";
            background-image: url("data:image/svg+xml;utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%22100%25%22%20height%3D%22100%25%22%20class%3D%22%22%20fill%3D%22rgba(255%2C%20255%2C%20255%2C%201.0)%22%3E%3Ctitle%3Etick%3C%2Ftitle%3E%3Cdesc%3ECreated%20with%20Sketch.%3C%2Fdesc%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill-rule%3D%22evenodd%22%20sketch%3Atype%3D%22MSPage%22%3E%3Cg%20sketch%3Atype%3D%22MSArtboardGroup%22%3E%3Cpath%20d%3D%22M6%2C10%20L2.5%2C6.5%20L0.5%2C8.5%20L6%2C14%20L15.5%2C4.5%20L13.5%2C2.5%20L6%2C10%20Z%22%20sketch%3Atype%3D%22MSShapeGroup%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
          }
        }
      }
    }
  }

  h2 {
    font-size: 64px;
    font-family: Bungee, cursive;
    color: rgb(255, 214, 0);
    text-align: center;
  }
  h3 {
    font-size: 48px;
    // font-family: "Alfa Slab One", cursive;
    color: rgb(253, 126, 48);
    letter-spacing: 1px;
    text-align: center;
  }
  h4 {
    font-size: 26px;
    color: white;
    // font-family: "Alfa Slab One", cursive;
    letter-spacing: 1px;
    text-align: center;
  }
  p {
    font-size: 18px;
    font-weight: bold;
    color: white;
    font-family: "Roboto", sans-serif;
    letter-spacing: 1px;
    text-align: center;
  }
  img {
    width: 50%;
  }
}
.relative {
  width: 100%;
  position: relative;
  display: inline-block;
}
.center-left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

@media (max-width: 768px) {
  .hotline-bar {
    display: none;
  }
  .relative {
    width: unset;
  }
  .center-left {
    position: unset;
    transform: unset;
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 999999 !important;
}

.slick-thumb {
  bottom: -80px !important;

  li {
    width: 64px;
    height: 64px;
    img {
      width: 100%;
      height: 100%;
    }

    &.slick-active {
      img {
        filter: brightness(50%) !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .slick-thumb {
    li {
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
