.ittc-footer {
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &-text {
    font-style: normal;
    text-transform: none;
    letter-spacing: 0px;
    font-size: 30px;
    font-family: "Roboto Condensed", sans-serif;
  }

  &-btn {
    width: 100%;
    text-align: center;
    border: none;
    padding: 10px;
    font-size: 20px;
    font-weight: 600;
    background: #fff;
    transition: all 0.3s;

    &:hover {
      background: #333;
      color: #fff;
    }
  }
}
