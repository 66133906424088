.comparison-section {
    text-align: center;
    padding: 2rem;
    overflow: visible;

    h2 {
        margin-bottom: 2rem;
        font-size: 2rem;
    }

    .card-row {
        display: flex;
        justify-content: center;
        gap: 2rem;
        margin-top: 1rem;

        .customer-card {
            position: relative;
            background-color: #001022;
            color: white;
            text-align: start;
            padding: 1.5rem;
            border-radius: 20px;
            width: 400px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            overflow: hidden; // Ensure content fits within the border-radius

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 20px;
                padding: 3px; // Border thickness
                background: linear-gradient(90deg, yellow, red);
                -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                pointer-events: none;
            }


            h3 {
                font-size: 1.5rem;
                margin-bottom: 1rem;
                color: #333;
            }

            .features-list {
                list-style: none;
                padding: 0;

                li {
                    margin-bottom: 0.75rem;

                    strong {
                        font-weight: bold;
                    }
                }
            }

            &:hover {
                transform: translateY(-10px);
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
            }
        }

        .vip {
            background-color: #f0f8ff;
            /* Light blue background for VIP */
            border-color: #0056b3;
        }
    }
}

.jumping-contact {
    animation: jump 1s infinite ease-in-out;
}

.coach-vas {
    max-width: 100%;
    height: auto;
}

.fsp {
    max-width: 100%;
    height: auto;
}

.yt-container {
    width: 460px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 480px) {
        width: 90%;
    }
}

// .price {
//     font-size: 2.5rem; // Large and bold
//     font-weight: bold;
//     color: #ffffff;
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent; // Makes text appear as gradient
//     position: relative;
//     text-align: center;

//     // Shine Effect
//     &::before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: -100%; // Start outside of the view
//         width: 100%;
//         height: 100%;
//         background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
//         transform: skewX(-20deg);
//         transition: all 0.4s ease-in-out;
//     }

//     &:hover::before {
//         left: 100%; // Moves across the text on hover
//         transition: all 0.4s ease-in-out;
//     }

//     // Pulsing Effect
//     &:hover {
//         animation: pulse 1s infinite;
//     }
// }

// // Keyframes for pulsing
// @keyframes pulse {

//     0%,
//     100% {
//         transform: scale(1);
//     }

//     50% {
//         transform: scale(1.1);
//     }
// }


@keyframes jump {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(-20px); // Jump up
    }

    50% {
        transform: translateY(0); // Back to starting position
    }

    70% {
        transform: translateY(-10px); // Slight bounce
    }

    100% {
        transform: translateY(0); // Reset position
    }
}