.word-by-word {
  display: inline-block;
  .word {
    opacity: 0;
    display: inline-block;
    animation: fadeIn 0.7s cubic-bezier(0.4, 0, 0.2, 1) forwards; /* Smooth animation */
    margin-right: 5px; /* Adjust spacing between words */
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px); /* Start with a slight upward movement */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at normal position */
    }
  }
}
