.iyl {
  background-color: #09153f;
  text-align: justify;
  color: white;

  .courseTitle {
    background-color: white;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    color: black;
    text-shadow: 0 0 20px orange;
    border: transparent;
    font-style: italic;
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  p {
    margin-bottom: 5px;
  }
  .quote {
    line-height: 2rem;
  }
  .fear {
    color: orange;
  }
  .pageBreak {
    background-color: #06122c;
    color: white;
    border-top: 3px #e6a257 solid;
    text-align: justify;
  }
  iframe {
    width: 100%;
  }
  .changePositive {
    p {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
  .changePositiveQuote {
    font-weight: bold;
    font-size: 20px;
    color: orange;
    line-height: 2rem;
    padding: 10px;
  }
}
