// Constant
$color-primary: #57d1c9;

@mixin hoverStyle() {
  .overlay {
    background: $color-primary !important;
    opacity: 0.8;
  }

  .shortInfo {
    opacity: 1;
    visibility: visible;
  }
}

@mixin transitionKits {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.gt-page {
  // Background Pofolio
  .gtText {
    width: 50%;
    height: calc(100vh - 64px);
    position: relative;

    .gtIntroduce {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 70%;
      p {
        text-transform: uppercase;
        font-weight: 700;
        color: $color-primary;
        font-size: 16px;
        letter-spacing: 1px;
      }

      h1 {
        font-weight: 700;
        color: #222;
        font-size: 70px;
        line-height: 1.2;
        margin-bottom: 20px;
      }

      .buttonSeeMore {
        background: $color-primary !important;
        border: 1px solid $color-primary !important;
        color: #fff !important;
        padding: 10px 14px;
        cursor: pointer;
        border-radius: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        outline: none;
      }
    }
  }

  .gtBackGround {
    width: 50%;
    background: $color-primary;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .gtBgImg {
      height: calc(90vh - 64px);
    }
  }

  // See More------------------------------
  .imgGt {
    margin: 0 auto;
    width: 70%;
    height: auto;
  }

  // Hình ảnh các khóa học đã giảng dạy
  .mainTitle {
    .topText {
      position: relative;
      margin-bottom: 10px;

      color: #999;

      letter-spacing: 2px;

      span {
        position: relative;
        display: inline-block;
        top: -2px;
        width: 80px;
        height: 3px;
        background: $color-primary;

        border-radius: 5px;
        margin-left: 10px;

        filter: blur(4px);
        -o-filter: blur(4px);
        -ms-filter: blur(4px);
        -moz-filter: blur(4px);
        -webkit-filter: blur(4px);
      }
    }

    h2 {
      font-size: 42px;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 52px;
    }
  }

  .gt-wrapperImg {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 30px;
    grid-row-gap: 20px;

    img {
      width: 100%;
      height: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    .gt-gridItem1 {
      grid-column: 1 / span 2;
      grid-row: 1 / span 4;
      cursor: pointer;
      position: relative;

      &:hover {
        @include hoverStyle;
      }
    }

    .gt-gridItem2 {
      grid-column: 3 / span 4;
      cursor: pointer;
      position: relative;

      &:hover {
        @include hoverStyle;
      }
    }

    .gt-gridItem3 {
      grid-column: 3;
      cursor: pointer;
      position: relative;

      &:hover {
        @include hoverStyle;
      }
    }

    .gt-gridItem4 {
      grid-column: 4;
      cursor: pointer;
      position: relative;

      &:hover {
        @include hoverStyle;
      }
    }

    .gt-gridItem5 {
      grid-column: 1 / span 2;
      cursor: pointer;
      position: relative;

      &:hover {
        @include hoverStyle;
      }
    }

    .gt-gridItem6 {
      grid-column: 3 / span 4;
      grid-row: 3 / span 5;
      cursor: pointer;
      position: relative;

      &:hover {
        @include hoverStyle;
      }
    }
  }

  // Quoutes

  .bgQuotes {
    height: 500px;
    width: 100vw;
    color: #fff;
    text-align: center;
    position: relative;
    text-shadow: 3px 4px 7px rgba(0, 0, 0, 0.8);

    .mainQuotes {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      p {
        font-size: 25px;
        line-height: 1.28;
        letter-spacing: 0.57px;
        margin-bottom: 20px;
      }

      h3 {
        font-style: italic;
        font-size: 25px;
        font-family: inherit;
        letter-spacing: 0.71px;
        text-transform: uppercase;
      }
    }
  }

  .tab button {
    color: #777;
    background: #fff;
    font-size: 17px;
    padding: 5px 16px;
    border: none;
    outline: none;
    cursor: pointer;

    margin-right: 10px;
    transition: 0.3s;
    position: relative;
  }

  .tab button.active {
    background-color: $color-primary;
    border-radius: 4px;
    color: #fff;
  }

  .tab button.active::after {
    position: absolute;
    left: 50%;
    bottom: -5px;

    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: $color-primary transparent transparent transparent;
  }

  .tabcontent {
    padding: 20px 0;
    transition: 0.3s;
    display: none;

    h3 {
      font-size: 40px;
      font-weight: 700;
      line-height: 1.4;
    }

    p {
      color: #999;
      margin: 1rem 0;
    }

    @media screen and (max-width: 768px) {
      h3 {
        font-size: 30px;
        padding: 0 10px;
      }

      p {
        padding: 0 10px;
      }
    }
  }

  .overlay {
    @include transitionKits;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 100%;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
  }

  .shortInfo {
    @include transitionKits;
    padding: 0 10px;
    position: absolute;
    color: #fff;
    opacity: 0;
    visibility: hidden;

    bottom: 20px;
    left: 20px;
  }

  @media screen and (max-width: 992px) {
    .gtText {
      width: 100%;
      height: calc(20vh + 64px);
    }

    .gtIntroduce {
      width: 70% !important;

      h1 {
        font-size: 40px !important;
      }
    }

    .gtBackGround {
      width: 100%;
      height: calc(80vh - 64px);
    }
  }

  @media screen and (max-width: 768px) {
    .gtBgImg {
      height: 70vh !important;
    }

    .gtIntroduce {
      width: 80% !important;
      padding: 0px !important;
    }

    .shortInfo {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 470px) {
    .gtBackGround {
      height: calc(60vh - 64px) !important;
    }
    .gtBgImg {
      height: 70vh !important;
    }

    .gtIntroduce {
      width: 80% !important;
      padding: 0px !important;
    }

    .gtIntroduce {
      width: 100% !important;
      padding: 0 20px !important;
      p {
        font-size: 14px !important;
        margin-bottom: 10px;
      }
      h1 {
        font-size: 34px !important;
      }
    }
    .gtBgImg {
      height: 50vh !important;
    }
  }

  @media screen and (max-width: 1100px) {
    .imgGt {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 992px) {
    .imgGt {
      width: 70% !important;
    }
  }

  @media screen and (max-width: 570px) {
    .imgGt {
      width: 70% !important;
    }
  }

  @media screen and (max-width: 1100px) {
    .gt-wrapperImg {
      .gt-gridItem1 {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .gt-gridItem1 {
      grid-column: 1 / span 4 !important;
      grid-row: 1;
    }

    .gt-gridItem2 {
      grid-column: 1 / span 4 !important;
      grid-row: 3;
    }

    .gt-gridItem3 {
      grid-column: 1 / span 2 !important;
      grid-row: 4;
    }

    .gt-gridItem4 {
      grid-column: 3 / span 4 !important;
      grid-row: 4;
    }

    .gt-gridItem5 {
      grid-column: 1 / span 4 !important;
      grid-row: 5;
    }

    .gt-gridItem6 {
      grid-column: 1 / span 4 !important;
      grid-row: 6 !important;
    }

    .bgQuotes {
      background-position: center center !important;
    }
  }

  @media screen and (max-width: 500px) {
    .gt-gridItem3 {
      grid-column: 1 / span 4 !important;
      grid-row: 4;
    }

    .gt-gridItem4 {
      grid-column: 1 / span 4 !important;
      grid-row: 5;
    }

    .gt-gridItem5 {
      grid-row: 6 !important;
    }
    .gt-gridItem6 {
      grid-row: 7 !important;
    }
  }

  @media screen and (max-width: 768px) {
    .mainQuotes {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 1100px) {
    .videoGt {
      width: 100% !important;
      height: 500px !important;
    }
  }

  @media screen and (max-width: 992px) {
    .videoGt {
      width: 100% !important;
      height: 400px !important;
    }
  }

  @media screen and (max-width: 520px) {
    .videoGt {
      width: 100% !important;
      height: 300px !important;
    }
  }
}
