.ittc-quotes {
  color: #fff;
  text-align: center;

  .quote {
    &-title {
      margin-bottom: 18px;
      font-weight: 600 !important;
      font-size: 20px !important;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #9e9e9e;
    }

    &-inner {
      font-weight: 300;
      font-style: italic;
      text-transform: none;
      font-size: 35px;
      line-height: 50px;
      letter-spacing: 0px;
      word-spacing: 0.3rem;
      font-family: "Montserrat", sans-serif;
    }
  }
}

@media screen and (max-width: 992px) {
  .quote-title {
    font-size: 18px !important;
  }

  .quote-inner {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 768px) {
  .quote-title {
    font-size: 16px !important;
  }

  .quote-inner {
    font-size: 23px !important;
  }
}
