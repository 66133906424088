$gold: #c2b46b;
$gold2: #9a866d;

.kts {
  background-color: #171717;
  color: #fafafa;

  .headline-h1,
  .headline-h2,
  .headline-h3,
  .headline-h4 {
    background-image: -webkit-linear-gradient(white, $gold);
  }
  .kts-banner {
    min-height: 100vh;
  }
  .kts-point {
    position: absolute;
    width: 100px;
    left: -90px;
    top: -40px;
  }
  .kts-target-audient {
    .box {
      padding: 25px;
      font-style: italic;
      position: relative;
      background: #454545;
      height: 100%;
      span {
        font-family: "Montserrat", "Arial", sans-serif;
        background: -webkit-linear-gradient(white, #c4a668);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        font-size: 38px;
        line-height: 38px;
        font-weight: 800;
        display: block;
      }
    }
  }

  .kts-experience {
    .kts-experience-content {
      background-image: linear-gradient($gold, $gold2);
      background-size: 2px 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .kts-experience-left-content,
    .kts-experience-right-content {
      div {
        min-height: 50px;
        align-items: center;
      }
    }
    .wrap {
      text-align: center;
      position: relative;
    }
    .line {
      display: flex;
      justify-content: center;
      position: relative;
    }
    .wrap:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      border-top: 1px solid gray;
      background: black;
      width: 100%;
      transform: translateY(-50%);
    }
  }

  .kts-trainer {
    position: relative;
    padding-bottom: 80px;
    .kts-trainer-coachvas {
      position: relative;
      .kts-coachvas-content {
        position: absolute;
        bottom: 0;
        left: 20px;
        .img-coachvas-03 {
          position: absolute;
          width: 80%;
          bottom: 10%;
          left: 50%;
          transform: translate(-50%, -10%);
        }
      }
    }
    .kts-trainer-coach-thanh {
      position: relative;
      background-image: linear-gradient(
        rgba(55, 53, 54, 255),
        rgba(29, 29, 29, 255),
        rgba(29, 29, 29, 255)
      );
      border-radius: 20px;
      .right-content {
        background-color: rgba(44, 44, 44, 255);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 50px;
        position: relative;
      }
      .kts-trainer-coach-thanh-img {
        position: absolute;
        right: -20px;
        top: -20px;
        width: 150px;
      }
      .kts-trainer-box {
        background-color: transparent;
        border: 2px solid $gold2;
        padding: 20px;
        position: relative;
        ul {
          list-style-type: none;
          padding-left: 0;
          li {
            margin-bottom: 15px;
          }
        }
      }
      .kts-trainer-box:before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(-21deg, #f8acff 0%, #696eff 100%);
        transform: translate3d(0px, 0px, 0) scale(1.005);
        filter: blur(2px);
        opacity: var(0.65);
        transition: opacity 0.3s;
        border-radius: inherit;
      }

      /* 
        * Prevents issues when the parent creates a 
        * stacking context. (For example, using the transform
        * property )
        */
      .kts-trainer-box::after {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        border-radius: inherit;
      }
      .kts-trainer-box-img {
        width: 250px;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%);
      }
    }
  }
  .kts-value {
    .box {
      padding: 25px;
      border-radius: 20px;
      background-image: linear-gradient(to right, #15164d, #151515, #15164d);
      position: relative;
      .headline-h2 {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
      }
      .kts-value-content {
        padding-top: 20px;
        position: relative;
        padding-left: 15px;
        &:before {
          position: absolute;
          content: "";
          left: 0;
          border: 1px solid gray;
          width: 1px;
          height: calc(100% - 20px);
        }
        p {
          position: relative;
          &:before {
            position: absolute;
            content: "";
            left: -20px;
            top: 7px;
            // border: 1px solid gray;
            background-color: white;
            border: 1px blue solid;
            width: 10px;
            height: 10px;
            border-radius: 20px;
          }
        }
      }
    }
  }
  .kts-fixed-bottom {
    z-index: 888;
    position: fixed;
    bottom: 0;
    height: 80px;
    background-image: linear-gradient(rgba(55, 53, 54, 255), #171717);
    width: 100%;
    .img-register {
      position: absolute;
      img {
        position: absolute;
        height: 120px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
      .kts-btn-register {
        // position: absolute;
        font-size: 20px;
        margin-top: 0;
      }
    }

    .title-h2 {
      font-size: 28px !important;
    }

    .kts-fixed-bottom-content {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin-bottom: 0;
      }
    }
  }
  .kts-btn-register {
    margin-top: 20px;
    color: black;
    font-size: 24px;
    font-weight: 600px;
    padding: 10px;
    background: linear-gradient(to right, #cfaf75, #fed993b1, #cfaf75);
  }
}

@media screen and (max-width: 996px) {
  .kts-trainer-coach-thanh {
    .right-content {
      border-top-right-radius: unset !important;
      border-bottom-left-radius: 20px;
      .kts-trainer-text {
        font-size: 12px !important;
      }
    }
  }
  .kts-value {
    .box {
      .headline-h2 {
        top: -45px !important;
      }
      .kts-value-content {
        padding-top: 30px !important;
      }
    }
  }
  .kts-fixed-bottom {
    .img-register {
      right: 20px;
      bottom: 10px;
      img {
        height: 80px !important;
      }
      .kts-btn-register {
        font-size: 12px !important;
      }
    }
    .kts-fixed-bottom-content {
      display: block !important;
      .title-h2 {
        font-size: 18px !important;
      }
      p {
        margin-bottom: 15px !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .kts-trainer {
    .kts-trainer-content {
      display: block !important;
    }
    .kts-coachvas {
      .kts-coachvas-content {
        left: 10px !important;
        right: 40px;
      }
      .kts-value-content {
        padding-top: 30px !important;
      }
    }
    .kts-trainer-coach-thanh {
      .right-content {
        .kts-trainer-text {
          font-size: 12px !important;
        }
      }
    }
  }
  .kts-value {
    .box {
      .headline-h2 {
        top: -20px !important;
      }
    }
  }
  .kts-fixed-bottom {
    height: 60px;
    text-align: center;
    .img-register {
      right: 20px;
      bottom: 10px;
      img {
        height: 80px !important;
      }
      .kts-btn-register {
        font-size: 12px !important;
      }
    }

    h2 {
      font-size: 18px;
    }
    .title-h2 {
      font-size: 18px !important;
    }
    .kts-fixed-bottom-content {
      display: block !important;
      .title-h2 {
        font-size: 18px !important;
      }
      p {
        margin-bottom: 15px !important;
      }
    }
  }
}
