.countdown-container {
    color: lawngreen;
    /* Make text green */
    display: flex;
    gap: 30px;
    font-family: 'Courier New', Courier, monospace;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.countdown-item {

    font-size: 2em;
    font-weight: bolder;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}