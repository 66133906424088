.tag-course{
    --tw-text-opacity: 1;
    color: rgb(5 27 22/var(--tw-text-opacity));    
    background-color: rgb(76 207 175/var(--tw-bg-opacity));
    font-weight: 600;
    font-size: .75rem;
    line-height: 1rem;    
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.125rem;
    display: inline-block;
}