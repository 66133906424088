$primary-color: #ee4d2d;

.summaryCate {
  .heading {
    text-align: center;
    color: $primary-color;
    padding: 50px 0;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.cardCategory {
  text-align: center;
  border-radius: 5px;
  padding: 0 10px;

  &_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    &:hover {
      .cardCategory {
        filter: darker(1.75);
      }
    }

    &-text {
      margin-bottom: 10px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }

    &-title {
      height: 70px;
      margin-bottom: 50px;
      font-weight: bold;
    }

    &-img {
      margin: 0;
      width: 70%;
      height: 200px !important;
    }

    &-btn {
      margin-top: 20px;
      color: #fff;
      font-weight: bold;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }
}
