.title {
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
}

.description-home {
  font-size: 16px;
  font-weight: bold !important;
  margin-top: 0 !important;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 30px;
  }

  .description-home {
    font-size: 14px !important;
  }
}