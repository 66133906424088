.overview-course-media {
  background-color: white;
  border-radius: 15px;
  padding: 32px 20px;
  .break-line {
    width: 130px;
    height: 1px;
    background-color: blue;
  }
  p {
    margin-bottom: 0;
  }
  .action-purchase {
    p {
      margin-bottom: 0;
    }
  }
}
