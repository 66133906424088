$color-icon: white;
$bg-btn: #6bc63e;

.home-page-client {
  .lg:grid-cols-3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .course-item {
    border: 1px solid rgb(38 43 66);
    transition: all 0.4s ease-out 0s;
    z-index: 1;
    background-color: rgb(18 23 42 / var(1));
    border-radius: 10px;
    margin-bottom: 20px;
    .course-item-image {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .cat-icon {
      font-size: 40px;
      color: $color-icon;
    }
    .text-base {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .course-item-content {
      padding: 24px;
    }
    .course-item-content-caption {
      justify-content: space-between;
      align-items: flex-start;
      display: flex;
      .course-item-content-title {
        color: $color-icon;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 8px;
        text-align: left;
        &:hover {
          color: rgb(22 248 182);
        }
      }
      .total-hour {
        margin-left: 0.2rem;
        font-weight: 700;
        --tw-bg-opacity: 1;
        background-color: rgb(38 43 66 / var(--tw-bg-opacity));
        color: rgb(255 117 211 / var(--tw-text-opacity));
        border-radius: 1rem;
        margin-top: -3px;
        padding: 0.25rem 0.75rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
    .course-item-content-description {
      color: #d3d8e8;
      margin-bottom: 12px;
    }
    .course-item-content-tag {
      margin-top: 0.5rem;
    }
  }
}
.course-item {
  &:hover {
    border: 1px solid rgb(78, 86, 125);
    box-shadow: 0 15px 25px rgba(78, 86, 125, 0.1);
    a {
      color: #fff;
      &::after {
        background-color: $color-icon;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cat-icon {
    font-size: 30px !important;
  }
  .course-item-caption {
    h2 {
      font-size: 24px !important;
      margin-bottom: 15px !important;
    }
    p {
      font-size: 14px !important;
      margin-bottom: 15px !important;
    }
  }
}
