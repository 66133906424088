.aboutMrVas {
  position: relative;
  background: #25252d;

  p {
    color: white;
  }

  .contentLeft {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 40px !important;
  }

  .img-vas {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
