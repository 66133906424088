.course-online{
  .MuiPaper-root{
    background-color: #282C38;
  }
  .MuiSvgIcon-root{
    color:white
  }
  background-color: #1D212C;
  .wrapper-menu-admin {
    .menu-selected {
      color: rgb(0, 171, 85) !important;
      font-weight: bold;
      svg {
        color: rgb(0, 171, 85);
      }
      li {
        background: rgba(0, 171, 85, 0.08) !important;
      }
    }
    a{
      color: #333;
      &:hover {
        color: #999;
        svg {
          color: #5361FD;
        }
        & > li {
          background:#303440 !important;
          margin-left: 13px;
          border-radius: 24px 0px 0px 24px;
          border-right:18px orange solid;
        }
      }
    }
  }
  .wrapper-single-form {
    padding: 116px 16px;
  }
  .table-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__icon {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .course-online-search-bar{
    border-radius: 20px;
    background: #282C38;
    margin:8px;
  }
}
