.bfw {
  $c-lightPurple-2: #8675a9;
  $c-black-0: #333; // default
  $c-gray-3: #949494;
  $c-gray-4: #e4e4e4;
  $c-white-0: #fff; //default
  $c-lightPink-1: #ffd5cd;
  $c-lightPink-2: #efbbcf;
  $c-lightPurple-1: #c3aed6;
  $c-lightPurple-2: #8675a9;

  //default
  img {
    margin: auto;
  }
  ul {
    list-style-type: none;
  }
  // navbar
  .navbar {
    background: #fff;
    position: fixed;
    top: 60px;
    right: 0;
    width: 100%;
    transition: 0.5s;
    margin-bottom: 60px;
    z-index: 99;

    .listNavbar {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .itemNavbar {
        padding: 10px 60px;

        &:hover a {
          color: $c-lightPink-1;
        }
      }

      .img-logo {
        height: 60px;
      }

      .itemLogo {
        padding: 10px 30px;
      }
    }
    a {
      color: #333;
      font-size: 20px;
      text-decoration: none;
      transition: all 0.5s;
    }

    .navbar-collapse {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .navbar-nav {
      width: 100%;
      margin: 0 auto;
    }
  }

  .navbar-dp-small {
    display: none;
  }

  .navbar-dp-big {
    display: block;
  }

  .navMobile {
    height: 0;
    transition: all 0.5s;
  }

  @media screen and (max-width: 768px) {
    .navbar-dp-big {
      display: none;
    }

    .navbar-dp-small {
      display: block;
    }

    .navbar {
      &.navbarSmall {
        .listNavbar {
          justify-content: space-between;
          .itemNavbar {
            cursor: pointer;
            i {
              color: #333;
            }
          }
        }
      }
    }

    .navMobile {
      display: none;
    }

    .nav-open {
      .navMobile {
        display: block;
        height: 100%;
        transition: all 0.5s;
        .itemNavbar {
          padding: 10px 20px;
          transition: all 0.3s;
          &:hover {
            background-color: $c-lightPink-1;
            cursor: pointer;
          }

          a {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
    .navbarSmall {
      padding: 0;
    }
  }

  .letter-title {
    font-family: "Signwriter", sans-serif;
    font-size: 90px;
    color: #696969;
    &.thank-you {
      font-size: 60px;
      margin: 20px 0;
    }
  }

  .text-bolder {
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .letter-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  // BG
  .bg-course {
    img {
      width: 100%;
      height: auto;
    }
  }
  .course {
    h1 {
      color: $c-lightPurple-2;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 50px;
    }
  }

  // Giới thiệu chương trình

  .route-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .bg-description {
    background-color: $c-lightPink-2;
    text-align: center;
    padding: 50px 0;

    position: relative;
    text-align: center;
  }

  .bg-description p {
    font-size: 20px;
  }

  // Card lật

  .front-face,
  .contents,
  .back-face {
    position: absolute;
  }

  .center {
    height: 400px;
    width: 100%;
    transform-style: preserve-3d;
    perspective: 1000px;
  }

  .front-face,
  .back-face {
    height: 100%;
    width: 100%;
    text-align: center;
    background: $c-lightPink-1;
    background-size: cover;
    background-position: center;
    transform: translateY(0deg);
    border-radius: 10px;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  }
  .front-face::after {
    content: "";
    background-color: #000;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    opacity: 0.5;
  }
  .back-face {
    &.purple {
      background: $c-lightPurple-1 !important;
    }
  }

  .contents {
    left: 0%;
    top: 50%;
    width: 100%;
    perspective: 1000px;
    transform: translateY(-50%) translateZ(60px) scale(0.94);
  }

  .front p {
    font-size: 20px;
    padding: 10px;
    margin-bottom: 15px;
    color: #fff;
  }

  .front p:after {
    content: "";
    display: block;
    left: 0;
    right: 0;
    width: 100px;
    height: 2px;
    background: #fff;
    margin: 0 auto;
    margin-top: 10px;
  }

  .back-face {
    transform: rotateY(180deg);
    background: $c-lightPink-2;
  }

  .back {
    color: #fff;
  }

  .back p {
    padding: 10px;
    font-size: 16px;
  }

  .icons {
    margin: 10px 0;
    display: block;
  }

  i.fab {
    color: #042f4b;
    font-size: 20px;
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 50%;
    margin: 0 5px;
    line-height: 40px;
    cursor: pointer;
  }

  .center:hover > .back-face {
    transform: rotateY(0deg);
  }

  .center:hover > .front-face {
    transform: rotateY(-180deg);
  }

  .wrapper-card {
    display: flex;
    justify-content: center;
    margin: 30px auto;
  }
  .wrapper-bio {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // Form dang ky
  .wrapper-form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    .borderForm {
      background: #fff;
      border-radius: 20px;
      padding: 20px 50px;
      width: 600px;
    }
    .wrapper-button {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        color: #fff;
        margin-top: 20px;
        background-color: $c-lightPink-1;
        padding: 10px 20px;
        transition: all 0.5s;
        border: none;
        margin-bottom: 20px;
        border-radius: 10px;

        &:hover {
          background-color: #fdb6a8;
        }
      }
    }
  }

  .form-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;

    label {
      text-transform: uppercase;
      color: #ffbfb3;
      font-weight: 600;
    }

    input,
    select {
      width: 100%;
      padding: 5px 10px;
      outline: none;
      border: 2px solid $c-gray-4;

      &:focus {
        border: 2px solid $c-lightPink-2;
      }
    }

    ::placeholder {
      color: $c-gray-3;
      font-size: 15px;
      opacity: 1;
    }
  }

  .bg-dangKy {
    background: $c-lightPink-1;
  }

  .waves {
    position: relative;
    width: 100%;
    bottom: -60px;
  }

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }

  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .content {
      height: 30vh;
    }
    h1 {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    .waves {
      bottom: -50px;
    }
  }

  @media screen and (max-width: 1240px) {
    .navbar .listNavbar .itemNavbar {
      padding: 10px 40px;
    }
  }

  @media screen and (max-width: 1040px) {
    .navbar .listNavbar .itemNavbar {
      padding: 10px 30px;
    }
  }

  @media screen and (max-width: 940px) {
    .navbar .listNavbar .itemNavbar {
      padding: 10px 25px;
    }
  }

  @media screen and (max-width: 888px) {
    .navbar .listNavbar .itemNavbar {
      padding: 10px 20px;
    }
  }

  @media screen and (max-width: 840px) {
    .navbar .listNavbar .itemNavbar {
      padding: 10px 12px;
      a {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .img-logo {
      height: 40px !important;
    }
  }

  .bg-xinChao {
    margin-bottom: 20px;
  }

  .heading-title {
    font-family: "Crimson Text", serif;
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 700;
    color: #696969;
  }

  @media screen and (max-width: 992px) and (min-width: 768px) {
    .letter-title {
      font-size: 60px;
    }
  }

  @media screen and (max-width: 470px) {
    .letter-title {
      font-size: 60px;
    }
  }

  @media screen and (max-width: 768px) {
    .heading-title {
      font-size: 35px;
    }

    .wrapper-form .borderForm {
      border-radius: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .heading-title {
      font-size: 35px;
    }
  }

  .img-gif_big {
    display: block;
  }

  .img-gif_small {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .img-gif_big {
      display: none;
    }
    .img-gif_small {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        text-align: center;
      }
    }
    .thank-text {
      font-size: 50px !important;
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .thank-text {
      font-size: 40px !important;
      padding: 0 20px;
    }

    .quote {
      font-size: 20px !important;
    }
  }

  .heading-writer {
    font-family: "Signwriter", sans-serif;
    color: #696969;
    font-size: 70px;

    .margin-text {
      margin: 50px !important;
    }
  }

  .text-level {
    font-family: "Crimson Text", serif;
    font-weight: 600;
    margin-top: 30px;
  }

  .quote {
    font-size: 25px !important;
  }

  .img-khoa_1 {
    width: 150px;
    height: 150px;
  }

  .text-thank {
    font-size: 30px;
    margin-top: 40px;
  }

  .checkout-bfw-button {
    background-color: #732867;
    padding: 10px 50px;

    text-decoration: none !important;
    color: #fff;
    font-size: 20px;

    border-radius: 20px;

    &:hover {
      color: #fff;
    }
  }

  @media screen and (max-width: 552px) {
    .text-thank {
      font-size: 20px;
    }
  }

  .text-level {
    font-size: 26px;
  }

  .picture-bio {
    position: relative;

    .play-video_bio {
      position: absolute;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      cursor: pointer;
      font-size: 60px;

      color: #ffb1a3;
      transition: all 0.3s;

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
}
