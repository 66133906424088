@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Noto+Serif:wght@400;700&family=Open+Sans:wght@400;500&display=swap");

$font-title: "Montserrat", sans-serif;
$font-para: "Noto Serif", serif;
$font-heading: "Open Sans", sans-serif;
$width-content: 700px;
$color-quote: #0099df;

$color-normal: #333;
$color-lighter: rgb(113, 128, 150);

.lpe-blog-detail {
  max-width: $width-content !important;
  margin-right: auto;
  margin-left: auto;

  .author-container {
    padding: 10px 0;
    margin-bottom: 30px;

    .category {
      margin-top: 40px;
      margin-bottom: 10px;

      a {
        color: $color-lighter;
        font-family: $font-para !important;
        font-size: 14px;
      }
    }

    .blog-title {
      font-family: $font-title;
      color: #333;
      font-size: 35px;
    }

    .author-info-block {
      display: flex;
      align-content: center;
      margin-top: 20px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .author-info-block_name {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        & > .name {
          font-size: 16px;
        }

        & > .created-at {
          font-size: 13px;
          color: $color-lighter;
        }
      }
    }
  }

  .content-container {
    margin-bottom: 100px;

    b {
      font-weight: 700;
    }

    a {
      font-weight: 700;
      text-decoration: underline !important;
      color: #333 !important;
    }

    .blog-paragraph {
      font-size: 19px;
      font-weight: 300;
      line-height: 32px;
      margin: 0 0 10px;
      padding-top: 7px;
      color: #333;
      z-index: 2;
      font-family: $font-para;
    }

    .blog-heading {
      display: block;
      line-height: 1.5;
      font-family: $font-heading;
      margin: 0 0 10px;
      padding-top: 7px;
      color: #333;
      font-weight: 600;
    }

    .blog-embed {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .caption {
        padding: 8px 0;
        text-align: center;
        font-size: 14px;
        font-style: italic;
      }
    }

    .blog-delimiter {
      font-size: 30px;
      line-height: 65px;
      letter-spacing: 0.2em;
    }

    p.caption {
      padding: 8px 0;
      text-align: center;
      font-size: 14px;
      font-style: italic;
    }

    .blog-image {
      display: block;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .blog-image_stretched {
      margin-left: calc(-100vw / 2 + $width-content / 2);
      margin-right: calc(-100vw / 2 + $width-content / 2);

      .blog-image {
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      p.caption {
        padding: 8px 0;
        text-align: center;
        font-size: 18px;
        font-style: italic;
      }
    }

    blockquote {
      font-style: italic;
      color: $color-quote;
      padding: 15px 15px 5px 40px;
      border-left: 4px solid $color-quote;
      margin: 30px 0;

      span {
        font-size: 19px;
        font-weight: 400;
        line-height: 32px;
        margin: 0 0 20px;
        color: $color-normal;
      }
    }

    ul,
    ol {
      margin-bottom: 10px;
      & > li {
        font-size: 19px;
        font-weight: 300;
        line-height: 32px;
        padding-top: 7px;
        color: #333;
        z-index: 2;
        font-family: $font-para;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .lpe-blog-detail {
    padding: 0 15px;
  }

  .author-container {
    .blog-title {
      font-size: 30px !important;
    }
  }
}
