$color-icon: #0270ad;

.services {
  .service-caption {
    h2 {
      font-size: 43px;
      display: block;
      font-weight: 700;
      line-height: 1.4;
      margin-bottom: 22px;
    }

    p {
      color: #5e5e5e;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      padding: 0 15px;
    }
  }
}

.paralax-video {
  margin-top: 50px;
  min-height: 100vh;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .btn-playVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background: $color-icon;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      background: $color-icon;
      z-index: 1;
      opacity: 0.2;
      border-radius: 50%;
    }

    i {
      font-size: 20px;
      color: #fff;
      z-index: 2;
    }
  }
}

.person-test {
  width: 100vw;
  height: 400px;

  color: #fff;
  text-align: center;

  position: relative;

  .wrapper-content_person {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2,
    p {
      margin-bottom: 20px;
    }

    p {
      font-weight: bold;
    }

    .btn-person {
      background: #51ce82;

      border: none;
      padding: 10px 20px;
      color: #fff;
    }
  }
}

.wrapper-slick {
  position: relative;
  padding: 10px;

  img {
    width: 100%;
    height: 250px;
    border-radius: 20px;
  }

  .overlay {
    position: absolute;
    background-image: linear-gradient(to bottom,
        rgba(0, 0, 0, 0) 0%,
        #000 100%);
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 10px;
    left: 10px;
    border-radius: 20px;
    opacity: 0;
    transition: all 0.3s;
    cursor: pointer;
  }

  .description-pic {
    position: absolute;
    color: #fff;
    bottom: 6%;
    opacity: 0;
    padding: 20px;
    transition: all 0.3s;

    p {
      color: $color-icon;
    }
  }

  &:hover {
    .overlay {
      opacity: 0.8;
    }

    .description-pic {
      bottom: 0;
      opacity: 1;
    }
  }
}

.home-page {
  .services {
    i {
      color: $color-icon;
    }
  }

  .title {
    // background: linear-gradient(to right, #1e44bf 0%, #51ce82 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    color: $color-icon;
  }

  .testimonials {

    .tes-rating,
    .tes-name {
      color: $color-icon;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper-content_person {
    width: 100%;
    padding: 0 20px;
  }

  .wrapper-slick {
    img {
      width: 100%;
      height: 100px;
      border-radius: 20px;
    }
  }
}