.moe {
  background-color: #fbea5d;
  color: orange;

  .courseTitle {
    background-color: white;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    color: black;
    text-shadow: 0 0 20px orange;
    border: transparent;
    font-style: italic;
    margin-bottom: 0;
    padding-bottom: 10px;
  }
  .reason {
    border-radius: 50px 50px;
    border: 1px solid;
    box-shadow: -5px 5px orange;
    background: white;
    padding: 20px 0;
    p {
      margin-bottom: 15px;
    }
  }
  .thinkDiffirent {
    border-radius: 50px 50px;
    border: 1px solid;
    box-shadow: -5px 5px orange;
    background: white;
    padding: 20px 50px;
  }
  .mindset {
    border-radius: 50px 50px;
    border: 1px solid;
    box-shadow: -5px 5px orange;
    background: orange;
    padding: 30px;
    color: white;
    p {
      margin-bottom: 10px;
    }
  }
  iframe {
    width: 100%;
  }
  .color {
    color: #ff6633;
  }
  .value {
    border-radius: 50px 50px;
    border: 1px solid transparent;
    box-shadow: 5px 5px black;
    background-image: linear-gradient(to right, #fbb237, #f15a29);
    padding: 5px 20px;
    color: white;
    text-align: center;
  }

  @media (max-width: 768px) {
    .apple {
      margin-bottom: 10px;
    }
    .mindset {
      padding: 20px;
    }
  }
}
