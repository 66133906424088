.isMemberVipBox {
  margin-top: 20px;
  padding: 20px 30px;

  background-color: #eaf2fa;
  border-radius: 10px;
}

.isMemberVipBoxImg {
  width: 75px;
  img {
    text-align: center;
  }
}

.isMemberVipBoxText {
  font-size: 18px;
}

.giftBox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  padding: 2rem;
  position: relative;
  img {
    width: 100px;
    height: 100px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(102, 102, 102, 0.4);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 35px;
      color: #fff;
    }
  }
}

.giftBoxContent {
  padding: 5px 0;
  cursor: pointer;
  text-align: center;

  background: #ffe259; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffa751,
    #ffe259
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffa751,
    #ffe259
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 18px;
  color: #fff;
}

.moreInfoBtn {
  margin-top: 20px;
  width: 50%;
  background-color: #3777bc;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  text-transform: capitalize;
  transition: all 0.3s;

  &:hover {
    background-color: #448ad5;
    color: #fff;
  }
}
@media screen and (max-width: 768px) {
  .moreInfoBtn {
    width: 100%;
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .giftBox {
    img {
      max-width: 60px;
      height: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .giftBoxContent {
    font-size: 14px;
  }
}
