header {
  z-index: 1200 !important;
}

.filter-panel {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}
