.skillOfCourse {
  background: #f6f6f6;
  text-align: center;

  .details {
    background: white;
    transition: all 0.4s !important;
    padding: 40px 20px;

    &:hover {
      transform: scale(1.1) !important;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      cursor: default;
    }

    .description {
      p {
        height: 40px;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: center;

    img {
      width: 50px;
    }
  }
  @media screen and (max-width: 768px) {
    .details {
      &:hover {
        transform: scale(1) !important;
        cursor: default;
      }
    }
  }

  @media (max-width: 575.98px) {
    .icon {
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
