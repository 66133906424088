$color-status-1: #333;
$color-status: #333;
$size-icon: 100px;
$size-circle: 150px;
$border-width: 5px;

.wrapper-wrongpath {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  img {
    width: 30%;
    height: auto;
  }
  .text {
    font-size: 30px;
    text-align: center;
  }
}

.wrapper-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .product {
    font-family: "Roboto Condensed", sans-serif;
    width: 100%;
    .content-body_product {
      text-transform: uppercase;
      text-align: center;
      h3 {
        font-size: 25px;
        font-weight: 400;
      }
    }
    .check-failed {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      i {
        font-size: $size-icon;
        color: $color-status;
      }
    }
    i {
      font-size: $size-icon;
      color: white;
      padding: 50px;
      background-color: orange;
      border-radius: 50%;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper-wrongpath {
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 769px) {
  .wrapper-content {
    .product {
      width: 80% !important;
    }
  }
}
@media screen and (max-width: 545px) {
  .wrapper-content {
    .product {
      width: 100% !important;
      padding: 20px;
    }
  }
}

.wrapper-inprogress {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-back {
  text-align: center;
  display: block;
  color: #fff;
  background-color: #333;
  padding: 10px 10px;
  margin-top: 20px;

  &:hover {
    color: #fff;
  }
}
