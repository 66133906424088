.pr {
  text-align: justify;
  color: white;
  // background-image: linear-gradient(to bottom, #08a5df, #1e26af, #08a5df);
  background-color: #3782c4;
  .courseTitle {
    color: black;
  }
  .quote {
    margin: 40px auto;
    display: block;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: #cfc547;
    text-align: center;
    letter-spacing: 1px;
    text-shadow: 10px 10px 25px rgb(81, 67, 21), -10px 10px 25px rgb(81, 67, 21),
      -10px -10px 25px rgb(81, 67, 21), 10px -10px 25px rgb(81, 67, 21);
    font-size: 30px;
    text-transform: uppercase;
  }
  iframe {
    width: 100%;
  }
  h2 {
    // background-color: white;
    // width: 100%;
    // text-transform: uppercase;
    // text-align: center;
    // color: white;
    // text-shadow: 0 0 20px #08a5df;
    // // border-radius: 20px;
    // border: transparent;
    // font-style: italic;
    // margin-bottom: 0;
  }
  .problem {
    border-radius: 50px 50px;
    border: 1px solid;
    box-shadow: 4px 5px lightblue;
    background: white;
    padding: 20px;
    margin: 0;
    color: black;
  }
  img {
    margin: auto;
  }

  .visible-sm-xs {
    display: none !important;
  }

  @media (max-width: 768px) {
    .order-last {
      order: unset;
    }
    .hidden-sm-xs {
      display: none !important;
    }
    .visible-sm-xs {
      display: block !important;
    }
  }
}
