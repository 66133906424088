$color-text: #0f0a64;
$color-secondary: #9eacc7;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

.courseOffline-page {
  .bg-2 {
    top: 25%;
    height: 75%;
    // width: 125%;
  }

  img {
    width: 100%;
  }

  .course-offline-promo-price {
    background: -webkit-linear-gradient($color-text, $color-secondary);
    //   background: -webkit-linear-gradient(#bd0350, #ff00c8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .popup {
    transform: scale(0);
    position: absolute;
    transition: all 0.3s;
    min-width: 250px;
    min-height: 70px;
    background-color: #fff;
    color: #333;
    z-index: 99 !important;
    border-radius: 8px;
    padding: 10px;
    border: 3px solid #3777bc;
    top: -100px;
    right: 0;
    text-align: center;
    cursor: unset;

    h4 {
      color: #0056b3;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        color: black;
      }
    }
  }

  .mascot-phoenix,
  .mascot-unicorn {
    cursor: pointer;

    &:hover {
      .popup {
        transform: scale(1);
        top: 100px;
      }
    }
  }

  .mascot-dragon {
    position: absolute;
    width: 600px;
    top: 5%;
    left: 10%;
    cursor: pointer;

    &:hover {
      .popup {
        transform: scale(1);
        top: 250px;
      }
    }
  }

  .mascot-phoenix {
    width: 350px;
    top: 55%;
    left: 25%;
  }

  .mascot-unicorn {
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    top: unset;
  }

  .level {
    position: absolute;
    z-index: 1;
    cursor: pointer;

    .level-content {
      flex-direction: column;
      display: flex;
      align-items: end;

      &:hover {
        .popup {
          transform: scale(1);
          top: 0;
        }
      }

      .box {
        margin-top: 20px;
        background-color: white;
        border-radius: 20px;
        padding: 10px 20px;

        .eng-text {
          text-shadow: 1px 1px 1px;
          font-weight: bolder;
          color: #3f3678;
          font-size: 22px;
          line-height: 24px;
        }

        .vie-text {
          color: #4a338c;
          font-weight: bold;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    img {
      width: 150px;
    }
  }

  .level7 {
    left: 50%;
    transform: translateX(-50%);

    .level-content {
      align-items: center;
    }
  }

  .level6 {
    z-index: 9;
    top: 10%;
    right: 25%;

    .popup {
      z-index: 999;
    }
  }

  .level5 {
    top: 30%;
    right: 25%;
  }

  .level4 {
    top: 40%;
    left: 25%;

    .level-content {
      align-items: start;
    }

    z-index: 99;
  }

  .level3 {
    top: 53%;
    right: 25%;
    text-align: right;
  }

  .level2 {
    top: 75%;
    right: 25%;
    text-align: center;
  }

  .level1 {
    top: 85%;
    left: 25%;

    .level-content {
      align-items: start;
    }
  }

  .heading {
    &-text {
      font-weight: 500;
      padding-top: 30px;
      color: $color-text;
      font-family: "Poppins", sans-serif;
      margin-bottom: 0;
    }

    &-desc {
      color: $color-secondary;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
    }
  }

  .margin-custom {
    margin-top: 80px !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    left: 0%;
    top: 38%;
    width: 50px;
    height: 50px;
    overflow: overlay;

    &::after {
      background: #fff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
  }

  .swiper-button-next {
    left: unset;
    right: 0% !important;
  }

  @media screen and (max-width: 768px) {
    .relative {
      width: 100%;
    }

    .level {
      img {
        width: 50px;
      }

      .level-content {
        .box {
          padding: 5px 10px;

          .eng-text {
            font-size: 16px;
          }

          .vie-text {
            font-size: 12px;
          }
        }
      }
    }

    .level7,
    .level6,
    .level5,
    .level4,
    .level3,
    .level2,
    .level1 {
      left: 50%;
      right: 0;
      transform: translateX(-50%);

      .level-content {
        flex-direction: column;
        display: flex;
        align-items: center;
      }
    }

    .level5 {
      top: 21%;
    }

    .level4 {
      top: 35%;
    }

    .level3 {
      top: 48%;
    }

    .level2 {
      top: 65%;
    }

    .level1 {
      top: 80%;
    }

    .mascot-dragon,
    .mascot-phoenix,
    .mascot-unicorn {
      cursor: pointer;

      .popup {
        min-width: unset;
      }

      &:hover {
        .popup {
          transform: scale(1);
          top: 50px;
        }
      }
    }

    .mascot-dragon {
      width: 200px;
      top: 5%;
      left: 0%;
    }

    .mascot-phoenix {
      width: 150px;
      top: 20%;
      left: 5%;
    }

    .mascot-unicorn {
      width: 125px;
      left: 8%;
      top: 60%;
      transform: translateX(0);
    }

    .bg-2 {
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    .level {
      img {
        width: 50px;
      }

      .level-content {
        .box {
          padding: 5px 10px;

          .eng-text {
            font-size: 10px;
          }

          .vie-text {
            font-size: 8px;
          }
        }
      }
    }
  }
}

.other-course {

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .other-course {

    .swiper-button-prev,
    .swiper-button-next {
      display: flex !important;
    }
  }
}