.parallax-title {
    width: 40%;
    height: auto;
    position: relative;
    z-index: 2;
    animation: shakeAnimation 0.8s ease-in-out infinite;
}

.card-title-parallax {
    width: 40%;
    height: auto;
    position: relative;
    z-index: 2;
}

.card-title-parallax-vassanth {
    width: 40%;
    height: auto;
    position: relative;
    z-index: 2;
    // animation: scaleAnimation 5s ease-in-out infinite;
}

.title-text {
    height: auto;
    position: relative;
    z-index: 3;
    animation: scaleAnimation 5s ease-in-out infinite;
}

.arrow {

    animation: jumpAnimation 2s ease-in-out infinite;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes jumpAnimation {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-15px);
        /* Moves up */
    }

    50% {
        transform: translateY(0);
        /* Returns to the original position */
    }

    75% {
        transform: translateY(-10px);
        /* Moves up slightly less */
    }

    100% {
        transform: translateY(0);
        /* Returns to the original position */
    }
}

@keyframes shakeAnimation {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-10px);
        /* Move left */
    }

    50% {
        transform: translateX(10px);
        /* Move right */
    }

    75% {
        transform: translateX(-5px);
        /* Move left slightly */
    }

    100% {
        transform: translateX(0);
        /* Return to the original position */
    }
}